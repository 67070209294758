.messenger {
  height: calc(100% - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &--body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 15px 5px 15px 5px;
    margin-bottom: 55px;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    height: 46px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(240, 240, 240, 1);

    &--input {
      border: 0px;
      height: 35px;
      border-bottom: 1px solid gray;
      width: 100%;
      background-color: rgba(240, 240, 240, 1);
    }
  }
  &__msg {
    background-color: rgba(27, 134, 144, 0.4);
    padding: 2px 4px;
    height: auto;
    font-size: 14px;
    line-height: 18px;
    // margin-top: 8px;
    border-radius: 5px;
    width: 50%;
    text-align: center;
    &--container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
  &__author {
    width: 50%;
    padding: 5px;
    height: 20px;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    justify-content: flex-end;
  }
  &__msgpartner {
    background-color: #272d4f;
    padding: 2px 4px;
    height: auto;
    font-size: 14px;
    line-height: 18px;
    // margin-top: 8px;
    color: white;
    border-radius: 5px;
    width: 50%;
    text-align: center;
    &--container {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
  &__partnerauthor {
    width: 50%;
    padding: 5px;
    height: 20px;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    justify-content: flex-start;
  }
}
