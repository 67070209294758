.kialo {
  padding: 3rem;
  display: flex;
  justify-content: center;
  right: -120px;
  position: absolute;
  top: 350px;
  z-index: 5;

  &__container {
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--graph {
    background-color: blue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    &__segment {
    }
  }
}

@media (max-width: 1180px) {
  .kialo {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

circle {
  background-color: #4299ea;
}
