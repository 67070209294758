.Video__1 {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #a6cfd2;
}

.Video__0 {
  margin-top: 1rem;
}
.Video-main__1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Video-control-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: rgba(100, 100, 100, 0.5);
  padding: 5px 0;
  // &__0 {
  //   margin-top: 0px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;
  //   background-color: #a6cfd2;
  //   padding: 3px 0;
  //   height: auto;
  //   border-bottom-left-radius: 5px;
  //   border-bottom-right-radius: 5px;
  // }
  // &__1 {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 0 auto;
  //   position: absolute;
  //   bottom: 0;
  //   z-index: 10;
  //   width: 100%;
  //   background-color: rgba(100, 100, 100, 0.5);
  //   padding: 5px 0;
  // }
}

.Video-control-container.hidden {
  display: none;
  background-color: gray;
}

.Video-control-container .ots-video-control {
  width: 35px;
  height: 35px;
  margin: 0px 5px !important;
  // border: 1px solid white;
  border-radius: 50%;
  background-position: center;
  // background-color: rgba(27, 134, 144, 0.4);
  // background-color: lightgrey;
  background-repeat: no-repeat;
  cursor: pointer;
}

.Video-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.Video-control-container .ots-video-control.audio:hover,
.Video-control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.Video-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.Video-control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.Video-control-container .ots-video-control.end-call {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
  background-color: red;
}

.Video-video-container__1 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Video-mask {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 7px;
  color: white;
  background: #a6cfd2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Video-mask .react-spinner {
  position: absolute;
}

.Video-mask .message {
  font-weight: 200;
}

.Video-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.Video-mask .message.button {
  border: 1px solid white;
  padding: 20px 40px;
  border-radius: 6px;
  background-color: Transparent;
  color: white;
  font-family: sans-serif;
  font-size: medium;
}

.Video-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.Video-video-container__1 .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.Video-video-container__1 .video-container.small {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 14vw;
  height: 16vh;
  border: 1px solid #fcba00;
  z-index: 2;
}

.Video-video-container__1 .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.Video-video-container__1 .video-container.hidden {
  display: none;
}

.Video-video-container__1 .video-container.active-gt2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.Video-video-container__1 .video-container.active-gt2.hidden {
  display: none;
}

.Video-video-container__1 .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
}

.Video-video-container__1
  .video-container.active-gt2.active-odd
  .OT_subscriber:first-child {
  flex-basis: 100%;
}

@media (max-width: 500px) {
  .Video-video-container__1 {
    position: relative;
    width: 100%;
    height: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Video-control-container__1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 12%;
    background-color: rgb(184, 184, 184);
  }
  .Video-control-container__1 .ots-video-control {
    width: 40px;
    height: 40px;
    margin: 0px 5px !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-color: rgba(27, 134, 144, 0.4);
    background-color: lightgrey;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

$color-green: #00e676;
$color-red: #ff3d00;

.popicon {
  position: absolute;
  left: 25px;
  bottom: 75px;
  // background-color: red;
  height: 60px;
  width: 60px;
}
.video {
  &__0 {
    height: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    &__publisher {
      width: 100%;
      height: 30vh;
      min-height: 200px;
      position: relative;
      z-index: 0;
      border-radius: 5px;
    }
    &__subscriber {
      width: 100%;
      height: 30vh;
      position: relative;
      display: flex;
      min-height: 200px;
      justify-content: center;
      align-items: center;
      z-index: 0;
      border-radius: 5px;
      border: 1px solid lightgray;
      color: rgba(27, 134, 144, 0.4);
      margin-top: 8px;
    }
    &__controls {
      height: 15%;
      // background-color: rgba(100, 100, 100, 0.4);
      width: 100%;
      position: absolute;
      bottom: 3px;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      background-color: rgba(180, 180, 180, 0.4);
      display: flex;
      justify-content: center;
      color: $color-green;
      font-size: 35px !important;
      padding: 4px;
      cursor: pointer;
      align-items: center;
      margin: 0 5px;
      border-radius: 100%;
      &__end {
        color: $color-red;
        border-color: $color-red;
      }
    }
  }
  &__1 {
    width: 100%;
    height: 100%;

    &__subscriber {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: white;
    }
    &__publisher {
      width: 14%;
      height: 15%;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
    }
    &__controls {
      height: 8%;
      min-height: 60px;
      background-color: rgba(100, 100, 100, 0.4);
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      background-color: rgba(180, 180, 180, 0.4);
      display: flex;
      justify-content: center;
      color: green;
      font-size: 50px !important;
      padding: 4px;
      cursor: pointer;
      align-items: center;
      margin: 0 5px;
      border-radius: 100%;
      &__end {
        color: red;
        border-color: red;
      }
    }
  }
}

.video-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background: rgba(27, 134, 144, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.video-mask .react-spinner {
  position: absolute;
}

.video-mask .message {
  font-weight: 200;
}

.video-mask .message.with-spinner {
  position: absolute;
  top: 55%;
}

.video-mask .message.button {
  border: 1px solid white;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: Transparent;
  color: white;
  font-family: sans-serif;
  font-size: medium;
}
.video-mask .message.button:focus:active,
.message.button:focus,
.message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.video-video-container .video-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.video-video-container .video-container.small {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16vw;
  height: 8vh;
  border: 1px solid #fcba00;
  z-index: 2;
}

.video-video-container .video-container.small.left {
  left: 20px;
  border: 1px solid #00fcc2;
}

.video-video-container .video-container.hidden {
  display: none;
}

.video-video-container .video-container.active-gt2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.video-video-container .video-container.active-gt2.hidden {
  display: none;
}

.video-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto !important;
  height: auto !important;
}

.video-video-container
  .video-container.active-gt2.active-odd
  .OT_subscriber:first-child {
  flex-basis: 100%;
}

.video-control-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  background-color: red;
}

.video-control-container.hidden {
  display: none;
}

.video-control-container .ots-video-control {
  width: 50px;
  height: 50px;
  margin: 20px 0 !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-color: rgba(27, 134, 144, 0.4);
  background-color: lightgrey;
  background-repeat: no-repeat;
  cursor: pointer;
}

.video-control-container .ots-video-control.audio {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.video-control-container .ots-video-control.audio:hover,
.video-control-container .ots-video-control.audio.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.video-control-container .ots-video-control.video {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.video-control-container .ots-video-control.video.muted {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.video-control-container .ots-video-control.end-call {
  background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
  background-color: red;
}
