$color-second-gold: #cbac63;

.blog {
  background-color: rgb(253, 253, 253);
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  &__content {
    max-width: 593px;
    margin-left: 1rem;
    h1 {
      font-family: "RoxboroughCF-Bold", sans-serif;
      font-size: 40px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 52px;
      letter-spacing: normal;
      color: #272d4f;
    }
    h2 {
      font-family: "RoxboroughCF-Bold", sans-serif;
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #272d4f;
      margin-top: 3rem;
    }
    h3 {
      font-family: AcherusGrotesque-Bold;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      margin: 20px 0px;
      letter-spacing: normal;
      color: #272d4f;
      text-align: justify;
    }
    h6 {
      margin: 0 7px 10px 0px;
      font-family: AcherusGrotesque-Regular;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: #686c84;
      text-transform: uppercase;
    }

    .paragraph {
      line-height: 16 * 1.618px;
      font-size: 16px;
      text-align: justify;
      //   padding: 1px 5px;
      font-family: AcherusGrotesque-Regular;
      &::first-letter {
        float: left;
        font-size: 6rem;
        line-height: 0.65;
        margin: 0.1em 0.1em 0.1em 0;
        color: #272d4f;
        font-family: "RoxboroughCF-Bold";
      }
    }
    .paragraph-2 {
      line-height: 16 * 1.618px;
      font-size: 16px;
      text-align: justify;
      //   padding: 1px 5px;
      font-family: AcherusGrotesque-Regular;
      margin-top: 1rem;
    }
    &--note {
      right: 105%;
      top: -30px;
      width: 200px;
      text-align: right;
      padding-right: 1rem;
      color: gray;
      font-style: italic;
      padding-left: 10px;
      border-right: 2px solid gray;
      font-size: 14px;
      line-height: 14 * 1.618px;
      font-family: "Handlee", cursive;
      position: absolute;
      &__blur {
        filter: blur(3px);
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none;
      }
      &__container {
        position: relative;
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 0;
      h6 {
        font-size: 13px;
        color: gray;
        font-weight: 600;
        line-height: 15px;
      }
      p {
        font-size: 12px;
        color: gray;
        line-height: 13px;
      }
    }
  }
  &__points {
    // background-color: #686c84;
    // background-color: #f5edde;
    background-color: $color-second-gold;
    padding: 16px;
    border-radius: 4px;
    // left: 120%;
    // top: -80px;
    width: 100%;
    margin: 1rem 0;
    padding: left;
    padding: 1rem;
    color: white;
    font-style: italic;
    padding-left: 10px;
    font-size: 13px;
    line-height: 13 * 1.5px;
    font-family: AcherusGrotesque-Regular;
    // position: absolute;
    p {
      font-size: 16px;
      text-align: center;
      margin-bottom: 0.6rem;
      font-style: normal;
    }

    &__list {
      list-style-type: circle;
      list-style-position: outside;
      margin-left: 1rem;
    }
    &__item {
      margin: 0.4rem;
    }
  }
  &__fixed {
    position: fixed;
    right: 20%;
    background-color: white;
  }
}

/* Some vars */
$background-color: hsl(50, 5, 97);

$white: $background-color;

.groucho {
  font-family: "Sanchez", serif;
  font-size: 1.4rem;
  line-height: 1.9rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  color: hsl(200, 40, 40);
  color: #272d4f;
  position: relative;
  background-color: transparent;
  z-index: 2;
  footer {
    font-family: "Noto Sans", sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    color: darken($white, 15%);

    float: right;

    &:before {
      content: "\2015";
    }
  }

  &:before {
    content: "\201C";
    position: absolute;
    top: 1.7rem;
    left: -2.5rem;
    color: darken($white, 7%);
    font-size: 6em;
    z-index: 1;
  }

  &:after {
    content: "\201D";
    position: absolute;
    bottom: -2.5rem;
    right: -1rem;
    font-size: 6em;
    font-style: italic;
    color: darken($white, 7%);
    z-index: 1;
  }
}

/* Some generic stuff. Don't worry about it */

@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700),
  url(https://fonts.googleapis.com/css?family=Sanchez:400italic,400),
  url(https://fonts.googleapis.com/css?family=Roboto:100);

@media (max-width: 560px) {
  .blog {
  }
}

@media (max-width: 560px) {
  .blog {
    background-color: rgb(253, 253, 253);
    padding: 3rem 1.8rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    &__container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
    }
    &__content {
      width: 100%;
      margin-left: 0em;
      h1 {
        font-family: "RoxboroughCF-Bold", sans-serif;
        font-size: 32px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #272d4f;
      }
      h2 {
        font-family: "RoxboroughCF-Bold", sans-serif;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #272d4f;
        margin-top: 3rem;
      }
      h3 {
        font-family: AcherusGrotesque-Bold;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        margin: 20px 0px;
        letter-spacing: normal;
        color: #272d4f;
      }
      h6 {
        margin: 0 7px 10px 0px;
        font-family: AcherusGrotesque-Regular;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #686c84;
        text-transform: uppercase;
      }

      .paragraph {
        line-height: 1.618;
        font-size: 15px;
        text-align: justify;
        //   padding: 1px 5px;
        font-family: AcherusGrotesque-Regular;
        &::first-letter {
          float: left;
          font-size: 4rem;
          line-height: 0.65;
          margin: 0.1em 0.1em 0.1em 0;
          color: #272d4f;
          font-family: "RoxboroughCF-Bold";
        }
      }
      .paragraph-2 {
        line-height: 1.618;
        font-size: 15px;
        text-align: justify;
        //   padding: 1px 5px;
        font-family: AcherusGrotesque-Regular;
        margin-top: 1rem;
      }
      &--note {
        right: 105%;
        top: -30px;
        width: 200px;
        text-align: right;
        padding-right: 1rem;
        color: gray;
        font-style: italic;
        padding-left: 10px;
        border-right: 2px solid gray;
        font-size: 14px;
        line-height: 14 * 1.618px;
        font-family: "Handlee", cursive;
        position: absolute;
        &__blur {
          filter: blur(3px);
          -webkit-user-select: none; /* Chrome all / Safari all */
          -moz-user-select: none; /* Firefox all */
          -ms-user-select: none; /* IE 10+ */
          user-select: none;
        }
        &__container {
          position: relative;
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2rem 0;
        h6 {
          font-size: 13px;
          color: gray;
          font-weight: 600;
          line-height: 15px;
        }
        p {
          font-size: 12px;
          color: gray;
          line-height: 13px;
        }
      }
    }
    &__points {
      // background-color: #686c84;
      // background-color: #f5edde;
      background-color: $color-second-gold;
      padding: 16px;
      border-radius: 4px;
      // left: 120%;
      // top: -80px;
      width: 100%;
      margin: 1rem 0;
      padding: left;
      padding: 1rem;
      color: white;
      font-style: italic;
      padding-left: 10px;
      font-size: 13px;
      line-height: 13 * 1.5px;
      font-family: AcherusGrotesque-Regular;
      // position: absolute;
      p {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0.6rem;
        font-style: normal;
      }

      &__list {
        list-style-type: circle;
        list-style-position: outside;
        margin-left: 1rem;
      }
      &__item {
        margin: 0.4rem;
      }
    }
    &__fixed {
      position: fixed;
      right: 20%;
      background-color: white;
    }
  }
  .groucho {
    font-family: "Sanchez", serif;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-top: 3rem;
    margin-bottom: 5rem;
    color: hsl(200, 40, 40);
    color: #272d4f;
    position: relative;
    background-color: transparent;
    z-index: 2;
    footer {
      font-family: "Noto Sans", sans-serif;
      font-size: 0.7rem;
      font-weight: 700;
      color: darken($white, 15%);

      float: right;

      &:before {
        content: "\2015";
      }
    }

    &:before {
      content: "\201C";
      position: absolute;
      top: 0rem;
      left: -1.5rem;
      color: darken($white, 7%);
      font-size: 5em;
      z-index: 1;
    }

    &:after {
      content: "\201D";
      position: absolute;
      bottom: 0rem;
      right: -1rem;
      font-size: 5em;
      font-style: italic;
      color: darken($white, 7%);
      z-index: 1;
    }
  }
}
