.arguments {
  margin: 3rem 0;
  display: flex;
  width: 1000px;
  justify-content: center;
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  &__item {
    width: 100%;
    padding: 16px 0;
    box-shadow: 0px 0px 2px rgba(220, 220, 220, 0.99);
    border-radius: 4px;
    background-color: white;
    h2 {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }
  }

  &__pros {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__item {
      width: 100%;
      padding: 12px 24px;
      box-shadow: 0px 1px 1px rgba(220, 220, 220, 0.99);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: white;
      border-right: 1px solid rgba(220, 220, 220, 0.99);
      border-top: 1px solid rgba(220, 220, 220, 0.99);

      h2 {
        font-size: 18px;
        text-align: center;
        color: #48cb92;
        font-weight: 900;
        font-family: sans-serif;
      }
      &__right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        width: 100%;
        padding: 12px 24px;
        box-shadow: 0px 1px 1px rgba(220, 220, 220, 0.99);
        border-right: 1px solid rgba(220, 220, 220, 0.99);
        border-top: 1px solid rgba(220, 220, 220, 0.99);
        background-color: white;
        h2 {
          font-size: 18px;
          color: #fd7361;
          text-align: center;
          font-weight: 900;
          font-family: sans-serif;
        }
      }
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__element {
    width: 100%;
    padding: 12px 24px;
    box-shadow: 1px 1px 3px rgba(220, 220, 220, 0.99);
    border-radius: 4px;
    background-color: white;
    margin-bottom: 8px;
    p {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
    }
  }
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px;
}
.MuiTypography-gutterBottom {
  margin-bottom: 0px;
}
