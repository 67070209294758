.materials {
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 4vw;
  &__preview {
    width: 100%;
    display: flex;
    height: auto;
    padding-left: 15px;
    &--img {
      width: 65px;
      height: 65px;
      &--container {
        width: 20%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
      }
    }
    &--title {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
      height: auto;

      font-size: 16px;
    }
  }
  &__itemscontainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
  }
  &__container {
    // border: 1px solid red;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--add {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      &--container {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    &--list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      &--title {
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
        font-size: 22px;

        font-weight: 400;
        padding: 40px 0;
      }
    }
  }
  &__item {
    width: 60%;
    display: flex;
    height: 120px;
    padding-left: 15px;
    &--img {
      width: 100px;
      height: 100px;
      &--container {
        width: 15%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &--title {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 50%;
      height: 100%;
      font-size: 16px;
    }
    &--func {
      width: 35%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }
  }
  &__buttoncontainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }
}
.pdf {
  width: 100%;
  height: 90vh;
  &__topcontainer {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1180px) {
  .materials {
    &__item {
      width: 100%;
      height: auto;
    }
  }
}
@media (max-width: 1180px) {
  .materials {
    &__container {
      &--add {
        &--container {
          width: 80%;
        }
      }
    }
    &__preview {
      width: 100%;
      display: flex;
      height: auto;
      padding-left: 15px;

      &--title {
        font-size: 13px;
      }
    }
    &__item {
      &--img {
        width: 75px;
        height: 75px;
      }
      &--title {
        font-size: 13px;
        height: auto;
      }
    }
  }
}
@media (max-width: 500px) {
  .materials {
    &__preview {
      &--img {
        width: 50px;
        height: 50px;
      }
      &--title {
        font-size: 11px;
      }
    }
    &__item {
      padding-right: 10px;
      &--img {
        width: 50px;
        height: 50px;
        &--container {
          width: auto;
        }
      }
      &--title {
        font-size: 11px;
        height: auto;
      }
      &--func {
        padding: 5px;
      }
    }
    &__container {
      &--add {
        &--container {
          width: 100%;
        }
      }
      &--list {
        &--title {
          font-size: 24px;
          padding: 20px 0;
        }
      }
    }
  }
}
