$color-green: #4caf50;
$color-red: #ff3d00;

.class {
  height: 100vh;
  &__0 {
    &__container {
      display: flex;
      width: 100%;
      background-color: rgb(245, 245, 245);
      height: calc(100vh - 64px);
      padding: 10px 10px;
    }
    &__video {
      width: 30%;
      min-width: 380px;
      height: 100%;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 5px lightgray;
    }
    &__content {
      width: 100%;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 0px 5px lightgray;
      margin-left: 10px;
    }
  }
  &__1 {
    &__video {
      width: 100%;
      height: calc(100vh - 64px);
    }
    &__content {
      display: none;
    }
  }
  &__popup {
    &__icons {
      display: flex;
      justify-content: center;
      width: 460px;
      margin-top: 1rem;
    }
    &--title {
      font-size: 19px;
      text-align: center;
    }
    &--label {
      font-size: 17px;
      text-align: center;
    }
    &--icon {
      background-color: $color-green;
      color: white !important;
      border-radius: 50%;
      font-size: 55px !important;
      padding: 10px;
      margin: 0 2.5rem;
      &-error {
        background-color: $color-red;
        color: white !important;
        border-radius: 50%;
        font-size: 55px !important;
        padding: 10px;
        margin: 0 2.5rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .class {
    height: 100vh;
    &__0 {
      &__container {
        display: flex;
        width: 100%;
        background-color: rgb(245, 245, 245);
        height: calc(100vh - 64px);
        padding: 10px 5px;
      }
      &__video {
        width: 0;
        min-width: 0;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 5px lightgray;
        visibility: hidden;
      }
      &__content {
        width: 100%;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 5px lightgray;
        margin-left: 0px;
      }
    }
    &__1 {
      &__video {
        width: 100%;
        height: calc(100vh - 64px);
      }
      &__content {
        display: none;
      }
    }
    &__popup {
      &__icons {
        display: flex;
        justify-content: center;
        width: 460px;
        margin-top: 1rem;
      }
      &--title {
        font-size: 19px;
        text-align: center;
      }
      &--label {
        font-size: 17px;
        text-align: center;
      }
      &--icon {
        background-color: $color-green;
        color: white !important;
        border-radius: 50%;
        font-size: 55px !important;
        padding: 10px;
        margin: 0 2.5rem;
        &-error {
          background-color: $color-red;
          color: white !important;
          border-radius: 50%;
          font-size: 55px !important;
          padding: 10px;
          margin: 0 2.5rem;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .class {
    &__popup {
      &__icons {
        width: 100%;
      }
    }
  }
}
