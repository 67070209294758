.docs {
  width: 100%;
  overflow: scroll;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    &--container {
      width: 100%;
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }
  }
  &--nav {
    width: 100%;
    height: 50px;
    display: flex;
  }
  &__input {
    width: 30%;
    height: auto;
    margin-top: 10px;
    padding: 5px;
  }
  &__create {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &--files {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;

    &--title {
      width: 100%;
      height: auto;
      font-size: 20px;
      display: flex;
      justify-content: center;
    }
    &--doc {
      width: 20px;
      height: 30px;
    }
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
    }
    &--filecontainer {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 14px 0px;
      flex-wrap: wrap;
    }
    &--name {
      font-size: 18px;
      margin-left: 10px;
    }
    &--file {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 260px;
      cursor: pointer;
    }
  }
}
@media (max-width: 1000px) {
  .docs {
    &__input {
      width: 50%;
    }
  }
}
@media (max-width: 800px) {
  .docs {
    &__input {
      width: 80%;
    }
    &__container {
      &--container {
        flex-direction: column;
      }
    }
    &--files {
      &--file {
        width: 80%;
      }
    }
  }
}
@media (max-width: 800px) {
  .docs {
    &__input {
      width: 100%;
    }
    &--files {
      &--file {
        width: 100%;
      }
    }
  }
}
