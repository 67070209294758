#mypopup {
  width: 400px;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  display: none;
  box-shadow: 0px 0px 3px rgba(220, 220, 220, 0.99);
}

// #mypopup::before {
//   content: "";
//   width: 12px;
//   height: 12px;
//   transform: rotate(45deg);
//   background-color: white;
//   position: absolute;
//   left: -6px;
//   top: 68px;
// }
