*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #333;
  line-height: 1.7;
  text-shadow: none;
}

@font-face {
  font-family: "RoxboroughCF-Thin";
  src: local("RoxboroughCF-Thin"),
    url(./assets/fonts/roxborough-cf-thin.ttf) format("truetype");
}

@font-face {
  font-family: "RoxboroughCF-Bold";
  src: local("RoxboroughCF-Bold"),
    url(./assets/fonts/roxborough-cf-bold.ttf) format("truetype");
}

@font-face {
  font-family: "AcherusGrotesque-Regular";
  src: local("AcherusGrotesque-Regular"),
    url(./assets/fonts/AcherusGrotesque-Regular.otf) format("opentype");
}

@font-face {
  font-family: "AcherusGrotesque-Bold";
  src: local("AcherusGrotesque-Bold"),
    url(./assets/fonts/AcherusGrotesque-Bold.ttf) format("truetype");
}
