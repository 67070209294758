.spinner {
  /* position: fixed; */
  z-index: 999;
  overflow: visible;
  margin: auto;
  width: auto;
  height: auto;
  padding: 30px;
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* background-color: rgba(226, 234, 227, 0.5); */
}

.lds_ring {
}
.lds_ring {
  background-color: white;
  border-radius: 50%;
  position: relative;
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: url("../../assets/spinner.png") 20px 16px no-repeat;
}
.lds_ring div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  border: 2px solid #cbac63;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #cbac63 transparent transparent transparent;
}
.lds_ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
